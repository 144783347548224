<template>
  <section>
    <b-modal
      ref="update-expiry-date"
      v-model="showModal"
      size="md"
      title="Update Expiry Date"
      class="p-4"
      @hide="hide"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="submitData"
        >
          <div
            v-if="dataLoad"
            class="spinner"
          >
            <b-spinner
              small
              class="d-inline-block"
            />
            <span class="sr-only">Loading...</span>
          </div>
          Update
        </b-button>
      </template>
      <div class="px-4">
        <b-form-group
          label-for="trial-expiry-date"
          label="Select Expiry Month"
        >
          <div
            class="py-2"
          >
            <month-picker
              :default-month="month"
              :default-year="year"
              @change="showDate"
            />
          </div>
        </b-form-group>
      </div>
      <b-overlay
        :show="dataLoad"
        no-wrap
      />
    </b-modal>
  </section>
</template>

<script>
import {
  BModal, BFormGroup, BButton, BOverlay, BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import { MonthPicker } from 'vue-month-picker'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BModal,
    BOverlay,
    BButton,
    BFormGroup,
    BSpinner,
    MonthPicker,
  },
  props: {
    dataClicked: {
      type: Object,
      required: true,
    },
  },
  emits: ['refresh-data'],
  data() {
    return {
      validation: null,
      showModal: false,
      dataLoad: false,
      required,
      year: null,
      month: null,
      date: {
        from: null,
        to: null,
        month: 1,
        year: 2024,
      },
      changedDate: false,
    }
  },
  computed: {
  },
  methods: {
    showDate(date) {
      this.date = date
      if (this.date.monthIndex < 10) {
        this.date.monthIndex = `0${this.date.monthIndex}`
      }
    },
    showExpModal() {
      this.showModal = !this.showModal
      this.extractFromISO(this.dataClicked.item.trial_expiry_date)
    },
    hide() {
      this.selectedDate = ''
    },
    extractFromISO(isoString) {
      const date = new Date(isoString)
      this.year = date.getFullYear()
      this.month = date.getMonth() + 1
    },
    async submitData() {
      try {
        if (this.year <= this.date.year) {
          if (this.year < this.date.year) {
            this.dataLoad = true
            const expiryDate = new Date(`${this.date.year}-${this.date.monthIndex}-01T23:59:59.000Z`).toISOString()
            const response = await this.$store.dispatch('admin/updateBusinessExpiryDate', { userHasGroupId: this.dataClicked.item.id, extend_trail_end_date: expiryDate })
            if (response) {
              this.$emit('refresh-data')
              this.$swal.fire({
                text: 'Expiry Date has been Updated.',
                icon: 'success',
              })
              this.dataLoad = false
              this.showModal = !this.showModal
            } else {
              showToast('Error', 'Some Thing Went Wrong! Please Try Again', 'danger')
              this.dataLoad = false
              this.showModal = !this.showModal
            }
          } else if (this.year === this.date.year) {
            if (this.month < this.date.monthIndex) {
              this.dataLoad = true
              const expiryDate = new Date(`${this.date.year}-${this.date.monthIndex}-01T23:59:59.000Z`).toISOString()
              const response = await this.$store.dispatch('admin/updateBusinessExpiryDate', { userHasGroupId: this.dataClicked.item.id, extend_trail_end_date: expiryDate })
              if (response) {
                this.$emit('refresh-data')
                this.$swal.fire({
                  text: 'Expiry Date has been Updated.',
                  icon: 'success',
                })
                this.dataLoad = false
                this.showModal = !this.showModal
              } else {
                showToast('Error', 'Some Thing Went Wrong! Please Try Again', 'danger')
                this.dataLoad = false
                this.showModal = !this.showModal
              }
            } else {
              this.$swal({
                text: 'The month selected is prior to the current expiry date of the Group.',
                icon: 'warning',
              })
            }
          }
        } else {
          this.$swal({
            text: 'The month selected is prior to the current expiry date of the Group.',
            icon: 'warning',
          })
        }
      } catch (e) {
        this.$swal({
          text: e.response.data.message,
          icon: 'error',
        })
        this.dataLoad = false
        this.showModal = !this.showModal
        showToast('Error', 'Some Thing Went Wrong! Please Try Again', 'danger')
      }
    },

  },
}
</script>
