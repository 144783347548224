<template>
  <div>
    <div class="d-flex justify-content-end mt-0" />
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="6"
            sm="12"
            offset-sm="0"
            offset-md="6"
            offset-lg="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @keyup.enter="pageChanged(1)"
              />
              <b-button
                variant="primary"
                @click="pageChanged(1)"
              >
                Search
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-skeleton-table
        v-if="spinner"
        :rows="paginationObject.limit"
        :columns="5"
        :table-props="{
          bordered: true,
          striped: true,
          tableClass: 'skeleton-loading-table',
        }"
      />
      <b-table
        v-else
        id="allGroups-table"
        hover
        selectable
        :per-page="paginationObject.limit"
        :select-mode="selectMode"
        :items="allGroups.results"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
        :sort-by.sync="sortBy"
      >
        <!-- Column: id -->
        <template #cell(Business_ID)="data">
          <span
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.id }}
          </span>
        </template>
        <template #cell(Business_Owner)="data">
          <span class="text-nowrap">
            {{ data.item.group_owner }}
          </span>
        </template>
        <!-- Column: User -->
        <template #cell(Business_Name)="data">
          <span
            class="text-nowrap d-inline-block text-truncate"
            style="max-width: 200px;"
          >
            {{ data.item.group_name }}
          </span>
        </template>
        <!-- Column: Email -->
        <template #cell(Business_Creation_Date)="data">
          <span class="text-nowrap">
            {{ data.item.group_created }}
          </span>
        </template>

        <template #cell(Trial_Expiry_Date)="data">
          {{ data.item.trial_expiry_date }}
        </template>

        <!-- Column: Status -->
        <template #cell(Actions)="data">
          <span
            class="d-flex justify-content-center"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              size="sm"
              class="btn-icon rounded-circle m-0"
              @click="setTrialDate(data)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
          </span>
        </template>
      </b-table>
    </b-card>
    <UpdateExpiryDate
      ref="update-expiry-date"
      :data-clicked="expiryDate"
      @refresh-data="onRefreshData"
    />
    <CustomPagination
      v-if="allGroups.total"
      :total="allGroups.total"
      :per-page="paginationObject.limit"
      :page="paginationObject.offset"
      @perPageChanged="perPageChanged"
      @pageChanged="pageChanged"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  VBToggle,
  BSkeletonTable,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import ActiveType from '@/common/enums/accountStatusEnum'
import CustomPagination from '@/components/common/CustomPagination.vue'
import UpdateExpiryDate from '@/components/admin/UpdateExpiryDate.vue'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    CustomPagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BSkeletonTable,
    UpdateExpiryDate,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      ActiveType,
      active: false,
      sortBy: 'STATUS',
      searchQuery: '',
      spinner: false,
      allGroups: [],
      deleteUserId: null,
      paginationObject: {
        offset: 1,
        limit: 10,
        total: 0,
      },
      tableColumns: [
        { key: 'Business_ID', label: 'Business ID' },
        { key: 'Business_Name', label: 'Business Name' },
        { key: 'Business_Owner', label: 'Business Owner' },
        { key: 'Business_Creation_Date', label: 'Business Creation Date' },
        { key: 'Trial_Expiry_Date', label: 'Trial Expiry Date' },
        {
          key: 'Actions', label: 'Update Trial Expiry Date', thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      selectMode: 'single',
      selected: [],
      expiryDate: {},
      month: '',
      date: '',
    }
  },
  async mounted() {
    await this.getAllGroups(this.paginationObject)
  },
  methods: {
    changeDateFromISOToSimple() {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.allGroups.limit; index++) {
        this.allGroups.results[index].trial_expiry_date = this.extractDateFromISO(this.allGroups.results[index].trial_expiry_date)
        this.allGroups.results[index].group_created = this.extractDateFromISO(this.allGroups.results[index].group_created)
      }
    },
    extractDateFromISO(isoString) {
      const date = new Date(isoString)
      this.month = date.getMonth() + 1
      this.date = date.getDate()

      if (this.month < 10) {
        this.month = `0${this.month}`
      }
      if (this.date < 10) {
        this.date = `0${this.date}`
      }
      return `${date.getFullYear()}-${this.month}-${this.date}`
    },
    setTrialDate(data) {
      this.expiryDate = { ...data }
      this.$nextTick(() => {
        this.$refs['update-expiry-date'].showExpModal()
      })
    },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllGroups(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllGroups(this.paginationObject)
    },
    async getAllGroups({ offset, limit }) {
      this.spinner = true
      const response = await this.$store.dispatch('admin/getBusinessTrialList', { offset, limit, query: this.searchQuery })
      if (response) {
        this.allGroups = this.$store.getters['admin/getBusinessTrialList']
        this.spinner = false
        this.changeDateFromISOToSimple()
      }
    },
    async onRefreshData() {
      await this.$store.dispatch('admin/getBusinessTrialList', { offset: this.paginationObject.offset, limit: this.paginationObject.limit, query: this.searchQuery })
      this.allGroups = this.$store.getters['admin/getBusinessTrialList']
      this.changeDateFromISOToSimple()
    },
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  .circular_image {
    border-radius: 40px;
    background-color: #eeedfd;
  }
  </style>
